import React from 'react'
import './Button.scss';

export const Button = ({text, onClick}) => {
    



    return (
        <button className="btn" onClick={onClick}>{text}</button>
    )
}
