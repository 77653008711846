import React from 'react'
import { DashboardsPage } from './../../Pages/DashboardsPage/DashboardsPage';
import { DevicesPage } from '../../Pages/DevicesPage/DevicesPage';
import { DocsPage } from '../../Pages/DocsPage/DocsPage';
import { SettingsPage } from '../../Pages/SettingsPage/SettingsPage';

export const HomeRouter = ({path}) => {
  
    switch(path){
        case '/dashboards':
            return <DashboardsPage/>
            
        case '/devices':
            return <DevicesPage />
            
        case '/settings':
            return <SettingsPage />
            
        case '/docs':
            return <DocsPage />
            
        default:
            break;

    }

}
