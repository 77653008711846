import React, { useState } from 'react';
import './GraphLine.scss';
import { Line } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import * as XLSX from 'xlsx'; // Importar la biblioteca XLSX
import moment from 'moment'; // Importar moment.js

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

export const GraphLine = ({ data, unit }) => {
  const [range, setRange] = useState('all');

  // Función para filtrar los datos según el rango seleccionado
  const filterDataByRange = (range) => {
    const labels = data.labels;
    const datasets = data.datasets;

    const now = moment();
    let filteredLabels = [];
    let filteredDatasets = datasets.map(dataset => ({
      ...dataset,
      data: []
    }));

    // Filtrar etiquetas usando moment y solo mostrar la fecha
    labels.forEach((label, index) => {
      const labelDate = moment(label, "HH:mm:ss DD/MM/YYYY"); // Formato original
      const formattedDate = labelDate.format("DD/MM/YYYY HH:mm:ss"); // Solo la fecha
      let includeLabel = false;

      switch (range) {
        case 'last6months':
          includeLabel = now.diff(labelDate, 'months') <= 6;
          break;
        case 'last30days':
          includeLabel = now.diff(labelDate, 'days') <= 30;
          break;
        case 'lastweek':
          includeLabel = now.diff(labelDate, 'days') <= 7;
          break;
        case 'last24hours':
          includeLabel = now.diff(labelDate, 'hours') <= 24;
          break;
        case 'last12hours':
          includeLabel = now.diff(labelDate, 'hours') <= 12;
          break;
        case 'last6hours':
          includeLabel = now.diff(labelDate, 'hours') <= 6;
          break;
        case 'lasthour':
          includeLabel = now.diff(labelDate, 'hours') <= 1;
          break;
        case 'last10minutes':
          includeLabel = now.diff(labelDate, 'minutes') <= 10;
          break;
        default:
          includeLabel = true; // Mostrar todos los datos
      }

      if (includeLabel) {
        filteredLabels.push(formattedDate); // Solo agregar la fecha
        datasets.forEach((dataset, datasetIndex) => {
          filteredDatasets[datasetIndex].data.push(dataset.data[index]);
        });
      }
    });

    console.log(filteredDatasets);
    console.log(filteredLabels);
    return {
      labels: filteredLabels,
      datasets: filteredDatasets,
    };
  };

  // Función para descargar los datos como un archivo Excel
  const downloadExcel = () => {
    const labels = data.labels;
    const datasets = data.datasets;

    // Crear los datos en un formato adecuado para Excel, agregando la columna 'Unit'
    const excelData = labels.map((label, index) => {
      let row = { Date: label, Unit: unit }; // Agregar fecha/hora como primera columna y Unit
      datasets.forEach(dataset => {
        row[dataset.label] = dataset.data[index]; // Agregar los valores de los datasets
      });
      return row;
    });

    const worksheet = XLSX.utils.json_to_sheet(excelData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Data");

    const now = new Date();
    const formattedDate = now.toISOString().split('T')[0];
    const formattedTime = now.toTimeString().split(' ')[0].replace(/:/g, '-');

    XLSX.writeFile(workbook, `graph_data_${formattedDate}_${formattedTime}.xlsx`);
  };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
      },
      tooltip: {
        callbacks: {
          label: function (context) {
            // Acceder directamente al dataset y label que ya están procesados
            const datasetLabel = context.dataset.label; // Label del dataset
            const datasetValue = context.raw; // Valor del punto actual
            const label = context.label; // Etiqueta de eje X para el punto actual
  
            return `${datasetLabel}: ${datasetValue} ${unit} (${label})`;
          },
        },
      },
    },
  };

  return (
    <div>
      <select onChange={(e) => setRange(e.target.value)} value={range}>
        <option value="all">Todos los datos</option>
        <option value="last6months">Últimos 6 meses</option>
        <option value="last30days">Últimos 30 días</option>
        <option value="lastweek">Una semana</option>
        <option value="last24hours">24Hs</option>
        <option value="last12hours">Últimas 12 horas</option>
        <option value="last6hours">Últimas 6 horas</option>
        <option value="lasthour">Última hora</option>
        <option value="last10minutes">Últimos 10 minutos</option>
      </select>

      <div className="chart-container">
        <Line options={options} data={filterDataByRange(range)} />
      </div>

      <button 
        onClick={downloadExcel} 
        style={{
          backgroundColor: '#4CAF50',
          border: 'none',
          color: 'white',
          padding: '5px 10px',
          textAlign: 'center',
          textDecoration: 'none',
          display: 'inline-block',
          fontSize: '12px',
          margin: '10px 2px',
          cursor: 'pointer',
          borderRadius: '5px',
          transition: 'background-color 0.3s ease'
        }}
        onMouseEnter={(e) => e.target.style.backgroundColor = '#45a049'}
        onMouseLeave={(e) => e.target.style.backgroundColor = '#4CAF50'}
      >
        Descargar Excel
      </button>
    </div>
  );
};
