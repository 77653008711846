const URL_PATH = process.env.REACT_APP_SERVER_URL;

// Función para obtener el token del localStorage
const getToken = () => localStorage.getItem('token');

export const loginUser = (email, password) => {
    // Objeto con las credenciales del usuario
    const credentials = {
        email: email,
        password: password
    };

    // Configuración de la solicitud
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(credentials)
    };

    // Hacer la solicitud al servidor para iniciar sesión
    return fetch(`${URL_PATH}/api/account/login`, requestOptions)
    .then(response => response.json())
        .then(data => {
            // Manejar la respuesta del servidor
            console.log('Login exitoso:', data);// Guardar los datos del usuario en el localStorage
            if(data.codeStatus == 200){
                localStorage.setItem('token', data.token);
            }
            return data;
            // Aquí puedes guardar el token JWT en el sessionStorage o localStorage
        })
        .catch(error => {
            console.error('Error al iniciar sesión:', error);
            return {status: 'error', statusCode: 504, error: 'Se ha producido un error desconocido. Vuelve a intentar'};
        });
};

export const getStatus = () => {

    
    const token = localStorage.getItem('token');

    // Configuración de la solicitud
    const requestOptions = {
        method: 'GET',
        headers: { 
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}` // Añadimos el Bearer token
        },
    };

    // Hacer la solicitud al servidor para iniciar sesión
    return fetch(`${URL_PATH}/api/account/status`, requestOptions)
    .then(response => response.json())
        .then(data => {
            // Manejar la respuesta del servidor
            console.log(data);// Guardar los datos del usuario en el localStorage
            return data;
            // Aquí puedes guardar el token JWT en el sessionStorage o localStorage
        })
        .catch(error => {
            console.error('Error al iniciar sesión:', error);
            return {status: 'error', statusCode: 504, error: 'Se ha producido un error desconocido. Vuelve a intentar'};
        });
};

export const registerUser = (user) => {

    // Configuración de la solicitud
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(user)
    };

    // Hacer la solicitud al servidor para registrar usuario
    return fetch(`${URL_PATH}/api/account/register`, requestOptions)
        .then(response => response.json())
        .then(data => {
            // Manejar la respuesta del servidor
            console.log('Registro exitoso:', data);
            if(data.codeStatus == 200){
                localStorage.setItem('token', data.token);
            }
            return data;
            // Aquí puedes guardar el token JWT en el sessionStorage o localStorage
        })
        .catch(error => {
            console.error('Error al registrar usuario:', error);
            return {status: 'error', statusCode: 504, error: 'Se ha producido un error desconocido. Vuelve a intentar'};
        });
};

export const registerPaidUser = (user) => {

    // Configuración de la solicitud
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(user)
    };

    // Hacer la solicitud al servidor para registrar usuario
    return fetch(`${URL_PATH}/api/account/register-paid`, requestOptions)
        .then(response => response.json())
        .then(data => {
            // Manejar la respuesta del servidor
            console.log('Registro exitoso:', data);
            if(data.codeStatus == 200){
                localStorage.setItem('token', data.token);
            }
            return data;
            // Aquí puedes guardar el token JWT en el sessionStorage o localStorage
        })
        .catch(error => {
            console.error('Error al registrar usuario:', error);
            return {status: 'error', statusCode: 504, error: 'Se ha producido un error desconocido. Vuelve a intentar'};
        });
};

export const deleteUser = (email) => {

    // Configuración de la solicitud
    const requestOptions = {
        method: 'DELETE',
        headers: { 'Content-Type': 'application/json' }
    };

    // Hacer la solicitud al servidor para registrar usuario
    return fetch(`${URL_PATH}/api/account/deleteAccount?email=${email}`, requestOptions)
        .then(response => response.json())
        .then(data => {
            // Manejar la respuesta del servidor
            console.log(data);
            return data;
            // Aquí puedes guardar el token JWT en el sessionStorage o localStorage
        })
        .catch(error => {
            console.error('Error al eliminar usuario:', error);
            return {status: 'error', statusCode: 504, error: 'Se ha producido un error desconocido. Vuelve a intentar'};
        });
};

export const forgotPassword = (email) => {

    // Configuración de la solicitud
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ email: email})
    };

    // Hacer la solicitud al servidor para registrar usuario
    return fetch(`${URL_PATH}/api/account/restore-password`, requestOptions)
        .then(response => response.json())
        .then(data => {
            // Manejar la respuesta del servidor
            console.log(data);
            return data;
            // Aquí puedes guardar el token JWT en el sessionStorage o localStorage
        })
        .catch(error => {
            console.error('Error al eliminar usuario:', error);
            return {status: 'error', statusCode: 504, error: 'Se ha producido un error desconocido. Vuelve a intentar'};
        });
};

export const registerSuccessPayment = (email) => {

    // Configuración de la solicitud
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({email})
    };

    // Hacer la solicitud al servidor para registrar usuario
    return fetch(`${URL_PATH}/api/account/register-paid-success`, requestOptions)
        .then(response => response.json())
        .then(data => {
            // Manejar la respuesta del servidor
            console.log(data);
            return data;
            // Aquí puedes guardar el token JWT en el sessionStorage o localStorage
        })
        .catch(error => {
            console.error('Error:', error);
            return {status: 'error', statusCode: 504, error: 'Se ha producido un error desconocido. Vuelve a intentar'};
        });
};

export const fetchUserData = () => {
    // Obtenemos el token del localStorage
    const token = localStorage.getItem('token');
    const email = localStorage.getItem('email');

    if (!token) {
        return Promise.reject({status: 'error', statusCode: 401, error: 'Token no disponible o no autorizado'});
    }

    // Configuración de la solicitud
    const requestOptions = {
        method: 'GET',
        headers: { 
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}` // Añadimos el Bearer token
        }
    };

    // Añadimos el email como parámetro en la URL
    const url = `${URL_PATH}/api/account/user?email=${email}`;

    // Hacer la solicitud al servidor para obtener los datos del usuario
    return fetch(url, requestOptions)
        .then(response => {
            if (!response.ok) {
                throw new Error(`Error HTTP: ${response.status}`);
            }
            return response.json();
        })
        .then(data => {
            // Manejar la respuesta del servidor
            console.log('Datos del usuario:', data);
            return data;
        })
        .catch(error => {
            console.error('Error al obtener los datos del usuario:', error);
            return {status: 'error', statusCode: 504, error: 'Se ha producido un error desconocido. Vuelve a intentar'};
        });
};

// Función para crear un nuevo dispositivo
export const createDevice = (device) => {
    const token = getToken();

    if (!token) {
        return Promise.reject({ status: 'error', statusCode: 401, error: 'Token no disponible o no autorizado' });
    }

    // Configuración de la solicitud
    const requestOptions = {
        method: 'POST',
        headers: { 
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}` // Añadimos el Bearer token
        },
        body: JSON.stringify(device)
    };

    return fetch(`${URL_PATH}/api/devices/create`, requestOptions)
        .then(response => response.json())
        .then(data => {
            console.log('Dispositivo creado:', data);
            return data;
        })
        .catch(error => {
            console.error('Error al crear dispositivo:', error);
            return { status: 'error', statusCode: 504, error: 'Se ha producido un error desconocido. Vuelve a intentar' };
        });
};

// Función para editar un dispositivo existente
export const editDevice = (id, updates) => {
    const token = getToken();

    if (!token) {
        return Promise.reject({ status: 'error', statusCode: 401, error: 'Token no disponible o no autorizado' });
    }

    // Configuración de la solicitud
    const requestOptions = {
        method: 'PUT', // Cambia a PUT para editar
        headers: { 
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}` // Añadimos el Bearer token
        },
        body: JSON.stringify(updates)
    };

    return fetch(`${URL_PATH}/api/devices/edit/${id}`, requestOptions)
        .then(response => response.json())
        .then(data => {
            console.log('Dispositivo actualizado:', data);
            return data;
        })
        .catch(error => {
            console.error('Error al editar dispositivo:', error);
            return { status: 'error', statusCode: 504, error: 'Se ha producido un error desconocido. Vuelve a intentar' };
        });
};

// Función para eliminar un dispositivo
export const deleteDevice = (id) => {
    const token = getToken();

    if (!token) {
        return Promise.reject({ status: 'error', statusCode: 401, error: 'Token no disponible o no autorizado' });
    }

    // Configuración de la solicitud
    const requestOptions = {
        method: 'DELETE', // Cambia a DELETE para eliminar
        headers: { 
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}` // Añadimos el Bearer token
        }
    };

    return fetch(`${URL_PATH}/api/devices/delete/${id}`, requestOptions)
        .then(response => response.json())
        .then(data => {
            console.log('Dispositivo eliminado:', data);
            return data;
        })
        .catch(error => {
            console.error('Error al eliminar dispositivo:', error);
            return { status: 'error', statusCode: 504, error: 'Se ha producido un error desconocido. Vuelve a intentar' };
        });
};

// Función para obtener todos los dispositivos del usuario
export const fetchUserDevices = () => {
    const token = getToken();
    const email = localStorage.getItem('email');

    if (!token) {
        return Promise.reject({ status: 'error', statusCode: 401, error: 'Token no disponible o no autorizado' });
    }

    // Configuración de la solicitud
    const requestOptions = {
        method: 'GET',
        headers: { 
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}` // Añadimos el Bearer token
        }
    };

    // Añadimos el email como parámetro en la URL
    const url = `${URL_PATH}/api/devices/${email}`;

    // Hacer la solicitud al servidor para obtener los datos del usuario
    return fetch(url, requestOptions)
        .then(response => {
            if (!response.ok) {
                throw new Error(`Error HTTP: ${response.status}`);
            }
            return response.json();
        })
        .then(data => {
            console.log('Dispositivos del usuario:', data);
            return data;
        })
        .catch(error => {
            console.error('Error al obtener los dispositivos del usuario:', error);
            return { status: 'error', statusCode: 504, error: 'Se ha producido un error desconocido. Vuelve a intentar' };
        });
};

// Función para crear un nuevo widget
export const createWidget = (widget) => {
    const token = getToken();

    if (!token) {
        return Promise.reject({ status: 'error', statusCode: 401, error: 'Token no disponible o no autorizado' });
    }

    // Configuración de la solicitud
    const requestOptions = {
        method: 'POST',
        headers: { 
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}` // Añadimos el Bearer token
        },
        body: JSON.stringify(widget) // Convertimos el objeto del widget a JSON
    };

    return fetch(`${URL_PATH}/api/widgets/create`, requestOptions)
        .then(response => response.json())
        .then(data => {
            console.log('Widget creado:', data);
            return data;
        })
        .catch(error => {
            console.error('Error al crear widget:', error);
            return { status: 'error', statusCode: 504, error: 'Se ha producido un error desconocido. Vuelve a intentar' };
        });
};

// Función para obtener todos los widgets del usuario
export const fetchUserWidgets = () => {
    const token = getToken();
    const email = localStorage.getItem('email');

    if (!token) {
        return Promise.reject({ status: 'error', statusCode: 401, error: 'Token no disponible o no autorizado' });
    }

    // Configuración de la solicitud
    const requestOptions = {
        method: 'GET',
        headers: { 
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}` // Añadimos el Bearer token
        }
    };

    // Añadimos el email como parámetro en la URL
    const url = `${URL_PATH}/api/widgets/${email}`;

    // Hacer la solicitud al servidor para obtener los datos del usuario
    return fetch(url, requestOptions)
        .then(response => {
            if (!response.ok) {
                throw new Error(`Error HTTP: ${response.status}`);
            }
            return response.json();
        })
        .then(data => {
            console.log('Widgets del usuario:', data);
            return data;
        })
        .catch(error => {
            console.error('Error al obtener los widgets del usuario:', error);
            return { status: 'error', statusCode: 504, error: 'Se ha producido un error desconocido. Vuelve a intentar' };
        });
};

export const getWidgetById = (id) => {
    const token = getToken();

    if (!token) {
        return Promise.reject({ status: 'error', statusCode: 401, error: 'Token no disponible o no autorizado' });
    }

    // Configuración de la solicitud
    const requestOptions = {
        method: 'GET',
        headers: { 
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}` // Añadimos el Bearer token
        }
    };

    // Añadimos el email como parámetro en la URL
    const url = `${URL_PATH}/api/widgets/widget/${id}`;

    // Hacer la solicitud al servidor para obtener los datos del usuario
    return fetch(url, requestOptions)
        .then(response => {
            if (!response.ok) {
                throw new Error(`Error HTTP: ${response.status}`);
            }
            return response.json();
        })
        .then(data => {
            console.log('Widget:', data);
            return data;
        })
        .catch(error => {
            console.error('Error al obtener widget:', error);
            return { status: 'error', statusCode: 504, error: 'Se ha producido un error desconocido. Vuelve a intentar' };
        });
};

export const editWidget = (widgetId, updates) => {
    const token = getToken();

    if (!token) {
        return Promise.reject({ status: 'error', statusCode: 401, error: 'Token no disponible o no autorizado' });
    }

    // Configuración de la solicitud
    const requestOptions = {
        method: 'PUT',
        headers: { 
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}` // Añadimos el Bearer token
        },
        body: JSON.stringify(updates)
    };

    return fetch(`${URL_PATH}/api/widgets/edit/${widgetId}`, requestOptions)
        .then(response => response.json())
        .then(data => {
            console.log('Widget actualizado:', data);
            return data;
        })
        .catch(error => {
            console.error('Error al editar widget:', error);
            return { status: 'error', statusCode: 504, error: 'Se ha producido un error desconocido. Vuelve a intentar' };
        });
};

export const deleteWidget = (widgetId) => {
    const token = getToken();

    if (!token) {
        return Promise.reject({ status: 'error', statusCode: 401, error: 'Token no disponible o no autorizado' });
    }

    // Configuración de la solicitud
    const requestOptions = {
        method: 'DELETE',
        headers: { 
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}` // Añadimos el Bearer token
        }
    };

    return fetch(`${URL_PATH}/api/widgets/delete/${widgetId}`, requestOptions)
        .then(response => response.json())
        .then(data => {
            console.log('Widget eliminado:', data);
            return data;
        })
        .catch(error => {
            console.error('Error al eliminar widget:', error);
            return { status: 'error', statusCode: 504, error: 'Se ha producido un error desconocido. Vuelve a intentar' };
        });
};


// Función para actualizar el orden de los widgets
export const updateWidgetsOrder = (widgets) => {
    const token = getToken();
    const userId = localStorage.getItem('userId');

    if (!token) {
        return Promise.reject({ status: 'error', statusCode: 401, error: 'Token no disponible o no autorizado' });
    }

    // Configuración de la solicitud
    const requestOptions = {
        method: 'PUT', // O PATCH dependiendo de tu API
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}` // Añadimos el Bearer token
        },
        body: JSON.stringify( { userId, newOrder: widgets }) // Enviamos el nuevo orden
    };

    return fetch(`${URL_PATH}/api/widgets/order`, requestOptions)
        .then(response => response.json())
        .then(data => {
            console.log('Orden de widgets actualizado:', data);
            return data;
        })
        .catch(error => {
            console.error('Error al actualizar el orden de los widgets:', error);
            return { status: 'error', statusCode: 504, error: 'Se ha producido un error desconocido. Vuelve a intentar' };
        });
};

export const changePlan = (newPlan, dataUser) => {
    const token = getToken();
    const email = localStorage.getItem('email');

    if (!token) {
        return Promise.reject({ status: 'error', statusCode: 401, error: 'Token no disponible o no autorizado' });
    }

    // Configuración de la solicitud
    const requestOptions = {
        method: 'PUT', // O PATCH dependiendo de tu API
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}` // Añadimos el Bearer token
        },
        body: JSON.stringify( { email, newPlan, dataUser }) // Enviamos el nuevo orden
    };

    return fetch(`${URL_PATH}/api/account/change-plan`, requestOptions)
        .then(response => response.json())
        .then(data => {
            console.log(data);
            return data;
        })
        .catch(error => {
            console.error('Error:', error);
            return { status: 'error', statusCode: 504, error: 'Se ha producido un error desconocido. Vuelve a intentar' };
        });
};

// https://api-sbx.dlocalgo.com/v1/subscription/plan/1730/subscription/all