// src/Components/ConfirmationPopup/ConfirmationPopup.js
import React from 'react';
import './ConfirmationPopup.scss';

const ConfirmationPopup = ({ message, onConfirm, onCancel }) => {
  return (
    <div className="confirmation-popup">
      <div className="popup-content">
        <p>{message}</p>
        <div className="popup-actions">
          <button className="confirm-btn" onClick={onConfirm}>Confirmar</button>
          <button className="cancel-btn" onClick={onCancel}>Cancelar</button>
        </div>
      </div>
    </div>
  );
};

export default ConfirmationPopup;
