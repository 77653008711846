import React, { useState } from 'react';
import './PopUpAddDevice.scss';
import { Bounce, toast, ToastContainer } from 'react-toastify';
import { createDevice, fetchUserData, fetchUserDevices } from '../../data/api';

export const PopUpAddDevice = ({ onClose }) => {
    const [deviceName, setDeviceName] = useState('');

    const handleAdd = async () => {
        const user = await fetchUserData();
        const devices = await fetchUserDevices();
        
        // Validaciones según el plan
        if (user.user.plan === 'free' && devices.devices.length >= 1) {
            toast.error('No puedes crear más equipos con el plan actual', {
                position: "bottom-right",
                autoClose: 5000,
                hideProgressBar: false,
                theme: "dark",
                transition: Bounce,
            });
            return;
        }

        if (user.user.plan === 'pro' && devices.devices.length >= 3) {
            toast.error('No puedes crear más equipos con el plan actual', {
                position: "bottom-right",
                autoClose: 5000,
                hideProgressBar: false,
                theme: "dark",
                transition: Bounce,
            });
            return;
        }

        if (deviceName === '') {
            toast.error('Por favor, ingresa un nombre para el dispositivo', {
                position: "bottom-right",
                autoClose: 3000,
                theme: "dark",
                transition: Bounce,
            });
            return;
        }

        const userId = localStorage.getItem('userId');
        
        // Lógica para crear el dispositivo
        const resp = await createDevice({
            name: deviceName,
            macAddress: null,
            userId: userId,
            variables: [],
        });
        if(resp.status === 'success'){
            toast.success('¡Dispositivo agregado exitosamente!', {
                position: "bottom-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
                theme: "dark",
                transition: Bounce,
            });
                
            setTimeout(() => {
                window.location.reload();
            }, 3000);
        } else {
            toast.error(resp.error, {
                position: "bottom-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
                theme: "dark",
                transition: Bounce,
            });
        }

        onClose(); // Cerrar el popup
    };

    return (
        <div className="popup-overlay">
            <div className="popup-add-device">
                <h3>Agregar dispositivo nuevo</h3>
                <input 
                    type="text" 
                    value={deviceName} 
                    onChange={(e) => setDeviceName(e.target.value)} 
                    placeholder="Nombre" 
                />
                <div className="popup-buttons">
                    <button className="btn-add" onClick={handleAdd}>Agregar</button>
                    <button className="btn-cancel" onClick={onClose}>Cancelar</button>
                </div>
            </div>
            
            <ToastContainer
                position="bottom-right"
                autoClose={3000}
                hideProgressBar
                theme="colored"
                transition={Bounce}
            />
        </div>
    );
};
