import React, { useState, useEffect } from 'react';
import './SettingsPage.scss';
import { deleteUser, fetchUserData, forgotPassword } from '../../data/api';
import { Bounce, toast, ToastContainer } from 'react-toastify';
import PlanSelectionPopup from '../../Components/PlanSelectionPopUp/PlanSelectionPopup';
import ConfirmationPopup from '../../Components/ConfirmationPopUp/ConfirmationPopup';
import { useNavigate } from 'react-router-dom';

export const SettingsPage = () => {
  const [userData, setUserData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [isConfirmationPopupOpen, setIsConfirmationPopupOpen] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const loadUserData = async () => {
      try {
        const data = await fetchUserData();
        setUserData(data.user);
      } catch (error) {
        console.error("Error fetching user data:", error);
      } finally {
        setLoading(false);
      }
    };

    loadUserData();
  }, []);

  const copyApiKey = () => {
    navigator.clipboard.writeText(userData?.apiKey);
    toast.success('API KEY copiada en el portapapeles', {
      position: "bottom-right",
      autoClose: 3000,
      hideProgressBar: true,
      theme: "colored",
      transition: Bounce,
    });
  };

  const openPlanPopup = () => {
    setIsPopupOpen(true);
  };

  const closePlanPopup = () => {
    setIsPopupOpen(false);
  };

  if (loading) {
    return <div className="loading">Cargando...</div>;
  }

  if (!userData) {
    return <div className="error">Error al cargar los datos</div>;
  }

  const { email, name, lastName, companyName, street, number, apiKey, plan, phone, country, postalCode, taxId, userType, ivaCondition } = userData;

  const taxIdMap = {
    Argentina: 'CUIL/CUIT',
    México: 'RFC',
    Colombia: 'NIT',
    Perú: 'RUC',
    Chile: 'RUT',
    Ecuador: 'RUC',
    Uruguay: 'RUT',
    Paraguay: 'RUC',
    Bolivia: 'NIT',
  };

  const openDeleteConfirmation = () => {
    setIsConfirmationPopupOpen(true);
  };

  const closeDeleteConfirmation = () => {
    setIsConfirmationPopupOpen(false);
  };

  const handleDeleteAccount = async () => {
    try {
      const resp = await deleteUser(localStorage.getItem('email'));
      if(resp.status === 'success'){
        toast.success('Cuenta eliminada correctamente', {
          position: "bottom-right",
          autoClose: 3000,
          hideProgressBar: true,
          theme: "colored",
          transition: Bounce,
        });
        
        setTimeout(() => {
          navigate('/')
        }, 500);
      } else{
        console.error("Error al eliminar la cuenta:", resp.error);
        toast.error(`Error al eliminar la cuenta: ${resp.error}`, {
          position: "bottom-right",
          autoClose: 3000,
          hideProgressBar: true,
          theme: "colored",
          transition: Bounce,
        });
      }
      // Aquí puedes redirigir al usuario o cerrar sesión, si es necesario
    } catch (error) {
      console.error("Error al eliminar la cuenta:", error);
      toast.error('Error al eliminar la cuenta', {
        position: "bottom-right",
        autoClose: 3000,
        hideProgressBar: true,
        theme: "colored",
        transition: Bounce,
      });
    } finally {
      closeDeleteConfirmation();
    }
  };

  return (
    <div className='settings-page'>
      <h2>Configuración</h2>

      <div className='settings-card'>
        <h3>Cuenta</h3>
        <div className='info-item'>
          <span>Correo electrónico</span>
          <div className='info-content'>
            <p>{email}</p>
            <div className='actions'>
              <button className='btn' onClick={ async() => {
                try {
                  // Aquí realizarías la lógica para enviar el correo de restablecimiento
                  const resp = await forgotPassword(email) // Simula una espera de 2 segundos
                  if(resp.status === 'success') {
                      toast.success('¡Correo de restablecimiento enviado!');
                  } else {
                    toast.error(resp.error)
                  }
                } catch (error) {
                  toast.error('Error al enviar el correo. Inténtalo de nuevo.');
                }
              }}>Cambiar contraseña</button>
            </div>
          </div>
        </div>
      </div>

      <div className='settings-card'>
        <h3>Información</h3>
        <div className='info-item personal'>
          <p>Nombre: {name} {lastName}</p>
          <p>Tipo de cuenta: {userType == 'individual' ? 'Individual' : 'Empresa'}</p>
          {companyName && <p>Empresa: {companyName}</p>}
          <p>Dirección: {street} {number}</p>
          <p>Teléfono: {phone}</p>
          <p>País: {country}</p>
          {userType == 'empresa' && <p>{taxIdMap[country]}: {taxId}</p>}
          {country == 'Argentina' && <p>Condición IVA: {ivaCondition}</p>}
        </div>
      </div>

      <div className='settings-card'>
        <h3>Plan Actual</h3>
        <div className='info-item'>
          <p>{plan.charAt(0).toUpperCase() + plan.slice(1)}</p>
          <div className='actions'>
            <button className='btn' onClick={openPlanPopup}>Cambiar de plan</button>
          </div>
        </div>
      </div>

      <div className='settings-card'>
        <h3>Clave API</h3>
        <div className='info-item'>
          <p>{apiKey}</p>
          <p className='copy-api-key' onClick={copyApiKey}>Copiar</p>
        </div>
      </div>

      {isPopupOpen && <PlanSelectionPopup onClose={closePlanPopup} currentPlan={plan} />}

      <div className='settings-card delete-account'>
        <button className='delete-btn' onClick={openDeleteConfirmation}>Eliminar cuenta</button>
      </div>

      <div className='contact-info'>
        <p>Si tenés alguna duda, sugerencia, problema o reclamo no dudes en contactarnos a nuestro correo <a href="mailto:contacto@ioteris.com.ar">contacto@ioteris.com.ar</a>.</p>
      </div>

      {isConfirmationPopupOpen && (
        <ConfirmationPopup
          message="¿Estás seguro de que deseas eliminar tu cuenta? Esta acción no se puede deshacer."
          onConfirm={handleDeleteAccount}
          onCancel={closeDeleteConfirmation}
        />
      )}
      
      <ToastContainer
        position="bottom-right"
        autoClose={3000}
        hideProgressBar
        theme="colored"
        transition={Bounce}
      />

    </div>
  );
};
