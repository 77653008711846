// ForgotPassword.js
import React, { useState } from 'react';
import './ForgotPassword.scss';
import { Bounce, ToastContainer, toast } from 'react-toastify';
import { forgotPassword } from '../../data/api';

export const ForgotPassword = () => {
  const [email, setEmail] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const handleForgotPassword = async () => {
    setIsLoading(true);
    try {
      // Aquí realizarías la lógica para enviar el correo de restablecimiento
      const resp = await forgotPassword(email) // Simula una espera de 2 segundos
      if(resp.status === 'success') {
          toast.success('¡Correo de restablecimiento enviado!');
          setEmail('');
      } else {
        toast.error(resp.error)
      }
    } catch (error) {
      toast.error('Error al enviar el correo. Inténtalo de nuevo.');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="forgot-password">
      <h2>Restablecer contraseña</h2>
      <p>Ingresa tu correo electrónico para recibir un enlace de restablecimiento.</p>
      
      <input
        type="email"
        placeholder="Correo electrónico"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        disabled={isLoading}
      />

      <button
        className='btn-send-email'
        onClick={handleForgotPassword}
        disabled={isLoading || !email}
      >
        {isLoading ? 'Enviando...' : 'Enviar'}
      </button>
      
      
      <ToastContainer
        position="bottom-right"
        autoClose={5000}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover={false}
        theme="dark"
        transition={Bounce}
        />
    </div>
  );
};
