import React, { useEffect } from 'react';
import './RegisterPage.scss'; // Importar el archivo de estilos

export const RegisterPage = () => {

  return (
    <div className="register-page">
        <h1 className="title"><span>IOT</span>ERIS</h1>
        <h2>Elige un plan</h2>
        <div className="plans">
            <div className="card">
            <h3>Plan Free</h3>
            <p>Ideal para principiantes y pequeños proyectos personales, permitiendo a los usuarios explorar la plataforma con funciones limitadas.</p>
            <ul>
              <li>1 equipo máximo</li>
              <li>100 MB de almacenamiento de datos</li>
              <li>2 widgets máximo</li>
            </ul>
            <p>Gratis</p>
            <a href="/register/free">Elegir</a>
            </div>
            <div className="card">
            <h3>Plan Pro</h3>
            <p>Diseñado para usuarios individuales que necesitan más capacidades que el plan gratuito, perfecto para proyectos personales avanzados.</p>
            <ul>
              <li>3 equipos máximo</li>
              <li>500 MB de almacenamiento de datos</li>
              <li>5 widgets máximo</li>
            </ul>
            <p>$15 USD/mes</p>
            <a href="/register/pro">Elegir</a>
            </div>
            <div className="card">
            <h3>Plan Full</h3>
            <p>Adaptado para empresas y proyectos más grandes, ofreciendo recursos ilimitados para una gestión extensa de IoT.</p>
            <ul>
              <li>Equipos ilimitados</li>
              <li>10 GB de almacenamiento de datos</li>
              <li>Widgets ilimitados</li>
            </ul>
            <p>$30 USD/mes</p>
            <a href="/register/full">Elegir</a>
            </div>
        </div>
    </div>
  );
};
