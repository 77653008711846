import React, { useEffect, useState } from 'react';
import './NavBar.scss';
import { NavLink, useNavigate } from 'react-router-dom';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import LogoutIcon from '@mui/icons-material/Logout';
import Tooltip from '@mui/material/Tooltip';

const AntTabs = styled(Tabs)(({ theme }) => ({
  borderBottom: '1px solid #191919',
  '& .MuiTabs-indicator': {
    backgroundColor: '#2D9CDB',
  },
  '& .MuiTabs-scrollButtons': {
    color: '#fff',
  },
}));

const AntTab = styled((props) => <Tab disableRipple {...props} />)(({ theme }) => ({
  textTransform: 'none',
  minWidth: 0,
  [theme.breakpoints.up('sm')]: {
    minWidth: 0,
  },
  fontWeight: theme.typography.fontWeightLight,
  marginRight: theme.spacing(1),
  color: '#FDFFFC',
  fontSize: 15,
  fontFamily: ['Kanit', 'sans-serif'].join(','),
  '&:hover': {
    color: '#2D9CDB',
    opacity: 1,
  },
  '&.Mui-selected': {
    color: '#2D9CDB',
    fontWeight: theme.typography.fontWeightMedium,
  },
  '&.Mui-focusVisible': {
    backgroundColor: '#2D9CDB',
  },
}));

export const NavBar = () => {
  const navigate = useNavigate();
  const [value, setValue] = useState(0);

  useEffect(() => {
    const root = window.location.pathname;

    switch (root) {
      case '/dashboard':
        setValue(0);
        break;
      case '/devices':
        setValue(1);
        break;
      case '/settings':
        setValue(2);
        break;
      case '/docs':
        setValue(3);
        break;
      default:
        break;
    }
  }, []);

  const handleChange = (event, newValue) => {
    switch (newValue) {
      case 0:
        navigate('/dashboard');
        break;
      case 1:
        navigate('/devices');
        break;
      case 2:
        navigate('/settings');
        break;
      case 3:
        navigate('/docs');
        break;
      default:
        break;
    }
    setValue(newValue);
  };

  const handleLogout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('email');
    localStorage.removeItem('userId');
    navigate('/'); // Redirige a la raíz
  };

  return (
    <Box sx={{ bgcolor: '#333333', maxWidth: "100vw", position: 'sticky', top: 0, zIndex: 10, display: 'flex', alignItems: 'center', flexGrow: 1 }}>
      <h1 className="title"><span>IOT</span>ERIS</h1>
      <AntTabs
        value={value}
        onChange={handleChange}
        variant="scrollable"
        scrollButtons
        allowScrollButtonsMobile
        textColor="primary"
        indicatorColor="primary"
      >
        <AntTab label='Dashboard' />
        <AntTab label='Dispositivos' />
        <AntTab label='Configuración' />
        <AntTab label="Documentación" />
      </AntTabs>
      <Tooltip title="Cerrar sesión">
        <IconButton 
          color="error" 
          onClick={handleLogout} 
          sx={{ marginLeft: 'auto', marginRight: 2 }} // Espaciado y alineación
        >
          <LogoutIcon />
        </IconButton>
      </Tooltip>
    </Box>
  );
};
