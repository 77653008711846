import React from 'react';
import './DocsPage.scss';

export const DocsPage = () => {
  return (
    <div className="docs-page">
      <h2>Documentación para Envío de Datos a ioteris</h2>
      <p>Para enviar datos a ioteris, realiza una solicitud POST a la siguiente URL:</p>
      <pre className="code-block">
        <code>
          https://www.api.ioteris.com.ar/api/data
        </code>
      </pre>
      <p>Incluye el siguiente bearer token en el header:</p>
      <pre className="code-block">
        <code>
          Authorization: Bearer YOUR_API_KEY
        </code>
      </pre>
      <p>En el body de la solicitud, incluye los siguientes valores:</p>
      <pre className="code-block">
        <code>
          {JSON.stringify({
            value: "valor del dato",
            unit: "unidad de medida",
            variable: "nombre de la variable",
            deviceId: "ID del dispositivo"
          }, null, 2)}  {/* Indentación de 2 espacios para una mejor visualización */}
        </code>
      </pre>

      <h3>Ejemplo con ESP32</h3>

      <h4>Arduino</h4>
      <pre className="code-block">
        <code>
          {`
#include <WiFi.h>
#include <HTTPClient.h>

const char* ssid = "YOUR_SSID";
const char* password = "YOUR_PASSWORD";
const String apiKey = "YOUR_API_KEY";
const String url = "https://www.api.ioteris.com.ar/api/data";

void setup() {
  Serial.begin(115200);
  WiFi.begin(ssid, password);
  while (WiFi.status() != WL_CONNECTED) {
    delay(1000);
    Serial.println("Conectando a WiFi...");
  }
  Serial.println("Conectado a WiFi");
  
  sendData(25, "°C", "temperatura", "ESP32_DEVICE_ID");
}

void sendData(float value, String unit, String variable, String deviceId) {
  if (WiFi.status() == WL_CONNECTED) {
    HTTPClient http;
    http.begin(url);
    http.addHeader("Authorization", "Bearer " + apiKey);
    http.addHeader("Content-Type", "application/json");

    String payload = String("{\"value\":") + value + 
                     ",\"unit\":\"" + unit + 
                     "\",\"variable\":\"" + variable + 
                     "\",\"deviceId\":\"" + deviceId + "\"}";

    int httpResponseCode = http.POST(payload);
    if (httpResponseCode > 0) {
      String response = http.getString();
      Serial.println(httpResponseCode);
      Serial.println(response);
    } else {
      Serial.print("Error en la solicitud: ");
      Serial.println(httpResponseCode);
    }
    http.end();
  }
}

void loop() {}
          `}
        </code>
      </pre>

      <h4>MicroPython</h4>
      <pre className="code-block">
        <code>
          {`
import network
import urequests
import time

ssid = 'YOUR_SSID'
password = 'YOUR_PASSWORD'
api_key = 'YOUR_API_KEY'
url = 'https://www.api.ioteris.com.ar/api/data'

def connect_wifi():
    wlan = network.WLAN(network.STA_IF)
    wlan.active(True)
    wlan.connect(ssid, password)
    while not wlan.isconnected():
        print('Conectando a WiFi...')
        time.sleep(1)
    print('Conectado a WiFi')

def send_data(value, unit, variable, device_id):
    headers = {
        'Authorization': 'Bearer ' + api_key,
        'Content-Type': 'application/json'
    }
    data = {
        'value': value,
        'unit': unit,
        'variable': variable,
        'deviceId': device_id
    }
    response = urequests.post(url, json=data, headers=headers)
    print(response.status_code)
    print(response.text)

connect_wifi()
send_data(25, '°C', 'temperatura', 'ESP32_DEVICE_ID')
          `}
        </code>
      </pre>

      <h3>Ejemplo con Raspberry Pi Pico W</h3>
      <pre className="code-block">
        <code>
          {`
import network
import urequests
import time

ssid = 'YOUR_SSID'
password = 'YOUR_PASSWORD'
api_key = 'YOUR_API_KEY'
url = 'https://www.api.ioteris.com.ar/api/data'

def connect_wifi():
    wlan = network.WLAN(network.STA_IF)
    wlan.active(True)
    wlan.connect(ssid, password)
    while not wlan.isconnected():
        print('Conectando a WiFi...')
        time.sleep(1)
    print('Conectado a WiFi')

def send_data(value, unit, variable, device_id):
    headers = {
        'Authorization': 'Bearer ' + api_key,
        'Content-Type': 'application/json'
    }
    data = {
        'value': value,
        'unit': unit,
        'variable': variable,
        'deviceId': device_id
    }
    response = urequests.post(url, json=data, headers=headers)
    print(response.status_code)
    print(response.text)

connect_wifi()
send_data(25, '°C', 'temperatura', 'PICO_DEVICE_ID')
          `}
        </code>
      </pre>
    </div>
  );
};
