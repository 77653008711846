import React, { useState } from 'react';
import './LoginPage.scss';
import { CircularProgress } from '@mui/material';
import { NavLink, Navigate } from 'react-router-dom'; // Importa Navigate desde react-router-dom
import { Bounce, ToastContainer, toast } from 'react-toastify';
import { loginUser } from '../../data/api';


export const LoginPage = ({}) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [loggedIn, setLoggedIn] = useState(false); // Estado para verificar si el usuario ha iniciado sesión

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (password.length < 6) {
        toast.error('La contraseña debe contener mínimo 6 caracteres', {
            position: "bottom-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
            progress: undefined,
            theme: "dark",
            transition: Bounce,
            });
        return;
    }
    // Simulación de solicitud al servidor con un retraso de 1 segundo
    setIsLoading(true);
    const resp = await loginUser(email, password);
    if (resp.status === 'success') {
      // Credenciales correctas
      localStorage.setItem('token', resp.token);
      localStorage.setItem('email', resp.email);
      localStorage.setItem('userId', resp.userId);
      setLoggedIn(true); // Establece loggedIn a true
    } else {
      // Usuario o contraseña incorrectos
      toast.error(resp.error, {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: "dark",
        transition: Bounce,
        });
    }

    // Una vez completada la simulación, puedes agregar la lógica real para enviar los datos al servidor aquí
    console.log('Email:', email);
    console.log('Contraseña:', password);

    // Limpia los campos después de enviar el formulario
    setIsLoading(false);
  };

  // Redirige al usuario a la página /home si está logueado
  if (loggedIn) {
    return <Navigate to="/dashboards" />;
  }

  return (
    <div className='login-background'>
      <div className='login-container'>
        <h1 className="title"><span>IOT</span>ERIS</h1>
        <form className='login-form' onSubmit={handleSubmit}>
          <input 
            type='email' 
            id='mail' 
            placeholder='Correo electrónico' 
            required
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <input 
            type='password' 
            id='password' 
            placeholder='Contraseña' 
            required
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <NavLink to='/forgot-password' className='login-form-forgot'>Olvidé mi contraseña</NavLink>
          {isLoading ? <CircularProgress style={{margin:'10px auto'}}/> : <button className='login-form-btn' type='submit'>Ingresar</button>}
          <p>¿No tienes cuenta? <NavLink to='/register' className='register-text'>Registrate</NavLink></p>
        </form>
      </div>
      <ToastContainer
        position="bottom-right"
        autoClose={5000}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover={false}
        theme="dark"
        transition={Bounce}
        />
    </div>
  );
};
