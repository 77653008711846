import React, { useEffect, useState } from 'react';
import './DevicesPage.scss';
import { Button } from '../../Components/Button/Button';
import { PopUpAddDevice } from '../../Components/PopUpAddDevice/PopUpAddDevice';
import { Device } from '../../Components/Device/Device';
import { Skeleton } from '@mui/material'; // Importar Skeleton de MUI
import { fetchUserData, fetchUserDevices } from './../../data/api';
import { PopUpMaxDevices } from '../../Components/PopUpMaxDevices/PopUpMaxDevices';

export const DevicesPage = () => {
    const [showPopUp, setShowPopUp] = useState(false);
    const [devicesData, setDevicesData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [showPopUpDevices, setShowPopUpDevices] = useState(false);
    const [maxDevices, setMaxDevices] = useState(0);

    const togglePopUp = () => {
        setShowPopUp(!showPopUp);
    };

    // Función para recuperar dispositivos del usuario
    const fetchData = async () => {
        try {
            const resp = await fetchUserDevices();
            if (resp.status === 'error') {
                throw new Error('Error al recuperar dispositivos');
            }
            console.log(resp);
            
            setDevicesData(resp.devices || []); // Asegúrate de que tu API devuelva la estructura esperada
            const response = await fetchUserData()
            if(response.status === 'success'){
                const plan = response.user.plan
                
                if( ( plan === 'free' && resp.devices.length > 1) || ( plan === 'pro' && resp.devices.length > 3)) {
                    setMaxDevices( plan === 'free' ? 1 : 3 )
                if(!showPopUpDevices){
                    setShowPopUpDevices(true)
                }
                }
            }
        } catch (err) {
            setError(err.message);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchData(); // Llamar a la función al montar el componente
    }, []);

    return (
        <div className="devices-page">
            <Button text="+ Nuevo dispositivo" onClick={togglePopUp} />
            <div className="devices-container">
                {loading ? (
                    // Mostrar skeletons mientras se carga
                    <div className="skeleton-container">
                        <Skeleton variant="rectangular" width={210} height={118} />
                        <Skeleton variant="rectangular" width={210} height={118} />
                        <Skeleton variant="rectangular" width={210} height={118} />
                    </div>
                ) : error ? (
                    // Mostrar error si ocurrió uno
                    <div className="error-message">{error}</div>
                ) : devicesData.length === 0 ? (
                    // Mensaje si no hay dispositivos
                    <div className="no-devices-message">No hay dispositivos disponibles.</div>
                ) : (
                    // Mostrar los dispositivos si hay
                    devicesData.map((device) => (
                        <Device 
                            key={device._id}
                            name={device.name}
                            id={device._id}
                            variables={device.variables}
                        />
                    ))
                )}
            </div>
            {showPopUp && <PopUpAddDevice onClose={togglePopUp} />}
            {showPopUpDevices && <PopUpMaxDevices maxDevices={maxDevices} onClose={() => {setShowPopUpDevices(false)}}/>}
        </div>
    );
};
