import React, { useEffect, useState } from 'react';
import './PopUpMaxWidgets.scss';
import { ToastContainer, toast } from 'react-toastify';
import { fetchUserWidgets, deleteWidget } from '../../data/api';

export const PopUpMaxWidgets = ({ onClose, maxWidgets }) => {
  const [widgets, setWidgets] = useState([]);
  const [selectedWidgets, setSelectedWidgets] = useState([]); // Cambiado de Set a array

  // Función para obtener los widgets del usuario
  const fetchWidgets = async () => {
    try {
      const response = await fetchUserWidgets(); // Obtener los widgets del usuario
      if (response.status === 'success') {
        setWidgets(response.widgets);
      }
    } catch (error) {
      console.error('Error fetching widgets:', error);
      toast.error('Error al cargar los widgets.');
    }
  };

  useEffect(() => {
    fetchWidgets();
  }, []);

  // Manejar la selección de widgets
  const handleToggleWidget = (widgetId) => {
    setSelectedWidgets((prevSelected) => {
      if (prevSelected.includes(widgetId)) {
        return prevSelected.filter((id) => id !== widgetId); // Eliminar si ya está seleccionado
      } else {
        return [...prevSelected, widgetId]; // Agregar si no está seleccionado
      }
    });
  };

  // Manejar la eliminación de widgets seleccionados
  const handleDeleteSelected = async () => {
    console.log(selectedWidgets.length);
    
    if (widgets.length - selectedWidgets.length > maxWidgets) {
      
      toast.error(`Debes eliminar hasta llegar a ${maxWidgets} widgets.`);
      return;
    }
    
    console.log('JJJJJJJJJJJJJJJJJJJJJJJJJJJJJJJJJJJJJJJ<');
    try {
      // Eliminar cada widget seleccionado
      const deletionPromises = selectedWidgets.map(async (widgetId) => {
        const response = await deleteWidget(widgetId); // Llama a la función de eliminación
        if (response.status !== 'success') {
          throw new Error(`Error al eliminar el widget con ID: ${widgetId}`);
        }
      });

      await Promise.all(deletionPromises); // Espera a que todas las eliminaciones se completen
      toast.success('Widgets eliminados correctamente.');
      setTimeout(() => {
        window.location.reload();
      }, 500);
    } catch (error) {
      console.error('Error deleting widgets:', error);
      toast.error('Error al eliminar los widgets.');
    }
  };

  return (
    <div className="popup-overlay">
      <div className="popup-content">
        <h2>Tienes más widgets de los permitidos por tu plan. Selecciona los widgets a eliminar</h2>
        <ul className="widget-list">
          {widgets.map((widget) => (
            <li key={widget._id} className="widget-item">
              <label className="widget-label">
                <input
                  type="checkbox"
                  checked={selectedWidgets.includes(widget._id)} // Cambiado de `has` a `includes`
                  onChange={() => handleToggleWidget(widget._id)}
                />
                <div className="widget-details">
                  <strong>{widget.title}</strong> 
                  <span className="widget-team">Equipo: {widget.device.name}</span>
                  <span className="widget-type">Tipo: {widget.type}</span>
                </div>
              </label>
            </li>
          ))}
        </ul>
        <button className="delete-button" onClick={handleDeleteSelected}>Eliminar seleccionados</button>
      </div>
      <ToastContainer />
    </div>
  );
};
