import React, { useState } from 'react';
import './RegisterFreePage.scss';
import { Logo } from '../../Components/Logo/Logo';
import { CircularProgress } from '@mui/material';
import { Bounce, ToastContainer, toast } from 'react-toastify';
import { registerUser } from '../../data/api'; // Asegúrate de que este sea el endpoint correcto
import { Navigate } from 'react-router-dom'; // Importa Navigate para redireccionar

export const RegisterFreePage = () => {
  const [currentStep, setCurrentStep] = useState(1);
  const [accountType, setAccountType] = useState('');
  const [showCompanyField, setShowCompanyField] = useState(false);
  const [name, setName] = useState('');
  const [companyName, setCompanyName] = useState('');
  const [email, setEmail] = useState('');
  const [confirmEmail, setConfirmEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [registered, setRegistered] = useState(false); // Nuevo estado para manejar la redirección

  const handleAccountTypeChange = (e) => {
    const selectedType = e.target.value;
    setAccountType(selectedType);
    setShowCompanyField(selectedType === 'empresa');
  };

  const handleNextStep = () => {
    setCurrentStep((prevStep) => prevStep + 1);
  };

  const handlePreviousStep = () => {
    setCurrentStep((prevStep) => prevStep - 1);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    // Validación de datos
    if (email !== confirmEmail) {
      toast.error('Los correos electrónicos no coinciden', {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: "dark",
        transition: Bounce,
      });
      return;
    }

    if (password.length < 6) {
      toast.error('La contraseña debe tener al menos 6 caracteres', {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: "dark",
        transition: Bounce,
      });
      return;
    }

    if (password !== confirmPassword) {
      toast.error('Las contraseñas no coinciden', {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: "dark",
        transition: Bounce,
      });
      return;
    }

    // Simulación de solicitud al servidor
    setIsLoading(true);
    const user = {
      name,
      email,
      password,
      userType: accountType,
      companyName: showCompanyField ? companyName : '',
      plan: 'free'
    };

    const resp = await registerUser(user);

    if (resp.status === 'success') {
      // Registro exitoso
      localStorage.setItem('token', resp.token);
      localStorage.setItem('email', resp.email);
      localStorage.setItem('userId', resp.userId);
      setRegistered(true); // Cambia el estado a true para redireccionar
      toast.success('Registro completado con éxito', {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: "dark",
        transition: Bounce,
      });
    } else {
      // Error en el registro
      toast.error(resp.error, {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: "dark",
        transition: Bounce,
      });
    }

    setIsLoading(false);
  };

  // Redirige al usuario al path /dashboards si se registra exitosamente
  if (registered) {
    return <Navigate to="/dashboards" />;
  }

  return (
    <div className="register-container">
      <div className="register-box">
        <Logo />

        {/* Visualizador de pasos */}
        <div className="steps-indicator">
          <div className={`step ${currentStep >= 1 ? 'active' : ''}`}>1</div>
          <div className={`step ${currentStep >= 2 ? 'active' : ''}`}>2</div>
        </div>

        {/* Paso 1: Datos de cuenta */}
        {currentStep === 1 && (
          <div className="step-content">
            <h2 className="plan-type">Datos de cuenta</h2>
            <form className="register-form">
              <input
                type="text"
                placeholder="Nombre"
                value={name}
                onChange={(e) => setName(e.target.value)}
                required
              />
              <select
                value={accountType}
                onChange={handleAccountTypeChange}
                required
              >
                <option value="" disabled>
                  Tipo de cuenta
                </option>
                <option value="individual">Individual</option>
                <option value="empresa">Empresarial</option>
              </select>
              {showCompanyField && (
                <input
                  type="text"
                  placeholder="Nombre de la compañía"
                  value={companyName}
                  onChange={(e) => setCompanyName(e.target.value)}
                  required
                />
              )}
              <button type="button" className="next-btn" onClick={handleNextStep}>
                Siguiente
              </button>
            </form>
          </div>
        )}

        {/* Paso 2: Registro */}
        {currentStep === 2 && (
          <div className="step-content">
            <h2 className="plan-type">Registro</h2>
            <form className="register-form" onSubmit={handleSubmit}>
              <input
                type="email"
                placeholder="Correo electrónico"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
              <input
                type="email"
                placeholder="Confirmar correo electrónico"
                value={confirmEmail}
                onChange={(e) => setConfirmEmail(e.target.value)}
                required
              />
              <input
                type="password"
                placeholder="Contraseña"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
              />
              <input
                type="password"
                placeholder="Confirmar contraseña"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                required
              />
              {isLoading ? (
                <CircularProgress style={{ margin: '10px auto' }} />
              ) : (
                <button type="submit" className="submit-btn-free">
                  Registrarse
                </button>
              )}
              <button type="button" className="prev-btn" onClick={handlePreviousStep}>
                Anterior
              </button>
            </form>
          </div>
        )}
      </div>
      <ToastContainer
        position="bottom-right"
        autoClose={5000}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover={false}
        theme="dark"
        transition={Bounce}
      />
    </div>
  );
};
