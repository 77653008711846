import React from 'react';
import './PlanSelectionPopup.scss'; // Asegúrate de tener este archivo para los estilos

const PlanSelectionPopup = ({ onClose, currentPlan }) => {

  const plans = [
    {
      title: 'Plan Free',
      name: 'free',
      description: [
        'Hasta 1 equipo',
        '100 MB de almacenamiento de datos',
        '2 widgets máximo',
      ],
      price: 'Gratis',
      link: `change-plan?from=${currentPlan}&to=free`,
    },
    {
      title: 'Plan Pro',
      name: 'pro',
      description: [
        'Hasta 3 equipos',
        '500 MB de almacenamiento de datos',
        '5 widgets máximo',
      ],
      price: '$15 USD/mes',
      link: `change-plan?from=${currentPlan}&to=pro`,
    },
    {
      title: 'Plan Full',
      name: 'full',
      description: [
        'Equipos ilimitados',
        '10 GB de almacenamiento de datos',
        'Widgets ilimitados',
      ],
      price: '$30 USD/mes',
      link: `change-plan?from=${currentPlan}&to=full`,
    },
  ];

  return (
    <div className="plan-popup">
      <div className="popup-content">
        <h2>Elige un plan</h2>
        <div className="plans">
          {plans.map((plan, index) => (
            <div 
              key={index} 
              className={`card ${currentPlan === plan.name ? 'selected' : ''}`}
            >
              <h3>{plan.title}</h3>
              <ul>
                {plan.description.map((item, idx) => (
                  <li key={idx}>{item}</li>
                ))}
              </ul>
              <p>{plan.price}</p>
              {currentPlan === plan.name ? (
                <button className="btn actual" disabled>Actual</button>
              ) : (
                <a href={plan.link} className="btn elegir">Elegir</a>
              )}
            </div>
          ))}
        </div>
        <button className="btn cerrar" onClick={onClose}>Cerrar</button>
      </div>
    </div>
  );
};

export default PlanSelectionPopup;
