import React, { useState } from 'react';
import './Device.scss';
import { deleteDevice, editDevice } from './../../data/api';

export const Device = ({ name, id, variables, onDelete }) => {
  const [deviceName, setDeviceName] = useState(name);
  const [isEditing, setIsEditing] = useState(false);
  const [newName, setNewName] = useState('');
  const [isConfirmingDelete, setIsConfirmingDelete] = useState(false); // Estado para confirmar eliminación

  const handleChangeNameClick = () => {
    setIsEditing(true);
    setNewName(deviceName); // Prellena el input con el nombre actual
  };

  const handleSaveName = async () => {
    try {
      const updates = { name: newName };
      const response = await editDevice(id, updates);
      
      if (response.status === 'success') {
        setDeviceName(newName); // Actualiza el estado con el nuevo nombre
        setIsEditing(false); // Cierra el modo de edición
      } else {
        alert(response.error); // Maneja el error según sea necesario
      }
    } catch (error) {
      console.error('Error al guardar el nuevo nombre:', error);
    }
  };

  const handleDeleteClick = () => {
    setIsConfirmingDelete(true); // Muestra la pregunta de confirmación
  };

  const handleConfirmDelete = async () => {
    try {
      const resp = await deleteDevice(id); // Asegúrate de que 'onDelete' es la función que elimina el dispositivo
      if (resp.status === 'success') {
        onDelete(id); // Llama a la función onDelete para actualizar la lista de dispositivos
      } else {
        alert(resp.message); // Maneja el error según sea necesario
      }
      setIsConfirmingDelete(false); // Cierra la pregunta de confirmación
    } catch (error) {
      console.error('Error al eliminar el dispositivo:', error);
    }
  };

  const handleCancelDelete = () => {
    setIsConfirmingDelete(false); // Cierra la pregunta de confirmación
  };

  return (
    <div className="device">
      <h3>{deviceName}</h3>
      <p>ID: {id}</p>
      <div className="device-data">
        <p>Datos:</p>
        <div className="device-variables">
          {variables.map((variable, index) => (
            <span key={index} className="device-variable">
              {variable.name}
            </span>
          ))}
        </div>
      </div>

      {isEditing ? (
        <div className="edit-name-container">
          <input
            type="text"
            value={newName}
            onChange={(e) => setNewName(e.target.value)}
            placeholder="Nuevo nombre"
          />
          <button className="save-button" onClick={handleSaveName}>
            Guardar
          </button>
          <button className="cancel-button" onClick={() => setIsEditing(false)}>
            Cancelar
          </button>
        </div>
      ) : (
        <button className="change-name-button" onClick={handleChangeNameClick}>
          Cambiar nombre
        </button>
      )}

      {isConfirmingDelete ? ( // Muestra la pregunta de confirmación de eliminación
        <div className="confirm-delete-container">
          <p>¿Estás seguro de que deseas eliminar este dispositivo?</p>
          <button className="confirm-button" onClick={handleConfirmDelete}>
            Sí, eliminar
          </button>
          <button className="cancel-button" onClick={handleCancelDelete}>
            Cancelar
          </button>
        </div>
      ) : (
        <button className="delete-button-device" onClick={handleDeleteClick}>
          Eliminar
        </button>
      )}
    </div>
  );
};
