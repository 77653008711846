import React, { useEffect, useState } from 'react';
import './PopUpMaxDevices.scss';
import { ToastContainer, toast } from 'react-toastify';
import { fetchUserDevices, deleteWidget, deleteDevice } from '../../data/api';

export const PopUpMaxDevices = ({ onClose, maxDevices }) => {
  const [devices, setDevices] = useState([]);
  const [selectedDevices, setSelectedDevices] = useState([]); // Cambiado de Set a array

  // Función para obtener los devices del usuario
  const fetchDevices = async () => {
    try {
      const response = await fetchUserDevices(); // Obtener los devices del usuario
      if (response.status === 'success') {
        setDevices(response.devices);
      }
    } catch (error) {
      console.error('Error fetching devices:', error);
      toast.error('Error al cargar los devices.');
    }
  };

  useEffect(() => {
    fetchDevices();
  }, []);

  // Manejar la selección de devices
  const handleToggleDevice = (deviceId) => {
    setSelectedDevices((prevSelected) => {
      if (prevSelected.includes(deviceId)) {
        return prevSelected.filter((id) => id !== deviceId); // Eliminar si ya está seleccionado
      } else {
        return [...prevSelected, deviceId]; // Agregar si no está seleccionado
      }
    });
  };

  // Manejar la eliminación de devices seleccionados
  const handleDeleteSelected = async () => {
    console.log(selectedDevices.length);
    
    if (devices.length - selectedDevices.length > maxDevices) {
      
      toast.error(`Debes eliminar hasta llegar a ${maxDevices} dispositivos.`);
      return;
    }
    
    console.log('JJJJJJJJJJJJJJJJJJJJJJJJJJJJJJJJJJJJJJJ<');
    try {
      // Eliminar cada device seleccionado
      const deletionPromises = selectedDevices.map(async (deviceId) => {
        const response = await deleteDevice(deviceId); // Llama a la función de eliminación
        if (response.status !== 'success') {
          throw new Error(`Error al eliminar el device con ID: ${deviceId}`);
        }
      });

      await Promise.all(deletionPromises); // Espera a que todas las eliminaciones se completen
      toast.success('Dispositivos eliminados correctamente.');
      setTimeout(() => {
        window.location.reload();
      }, 500);
    } catch (error) {
      console.error('Error deleting devices:', error);
      toast.error('Error al eliminar los devices.');
    }
  };

  return (
    <div className="popup-overlay">
      <div className="popup-content">
        <h2>Tienes más equipos de los permitidos por tu plan. Selecciona los dispositivos a eliminar</h2>
        <ul className="device-list">
          {devices.map((device) => (
            <li key={device._id} className="device-item">
              <label className="device-label">
                <input
                  type="checkbox"
                  checked={selectedDevices.includes(device._id)} // Cambiado de `has` a `includes`
                  onChange={() => handleToggleDevice(device._id)}
                />
                <div className="device-details">
                  <strong>{device.name}</strong> 
                  <span className="device-team">ID: {device._id}</span>
                </div>
              </label>
            </li>
          ))}
        </ul>
        <button className="delete-button" onClick={handleDeleteSelected}>Eliminar seleccionados</button>
      </div>
      <ToastContainer />
    </div>
  );
};
