import React, { useEffect } from 'react';
import './HomePage.scss';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

export const HomePage = () => {

  return (
    <div className="homepage">
      {/* Header */}
      <header className="header">
        <h1 className="logo"><span>IOT</span>ERIS</h1>
        <nav className="nav-links">
          <a href="#how-works">¿Cómo funciona?</a>
          <a href="#pricing">Precios</a>
          <a href="#faq">FAQ</a>
          <a href="/login">Inicia sesión</a>
        </nav>
      </header>

      {/* Hero Section */}
      <section className="hero">
        <div className="hero-text">
          <h1>Recopila todos tus datos.</h1>
          <h1>Visualízalos en cualquier momento.</h1>
          <h1>Monitorea tus equipos.</h1>
        </div>
        <img src={require('../../assets/landing-photo-1.png')} alt="Hero Illustration" />
        <img src={require('../../assets/landing-photo-2.png')} alt="Hero Illustration" />
      </section>

      {/* Features Section */}
      <section id="how-works" className="features">
        <h2>¿Cómo funciona?</h2>
        <p>
        IOTERIS te permite conectar fácilmente tus dispositivos y enviar datos a la nube de forma segura. Visualiza y monitorea tus métricas en tiempo real de manera sencilla. Con nuestra plataforma intuitiva, empezar con IoT es más fácil que nunca. Únete a nosotros y da vida a tus ideas IoT.
        </p>
      </section>

      {/* Pricing Section */}
      <section id="pricing" className="pricing">
        <h2>Precios</h2>
        <div className="pricing-cards">
          <div className="card">
            <h3>Plan Free</h3>
            <p>Ideal para principiantes y pequeños proyectos personales, permitiendo a los usuarios explorar la plataforma con funciones limitadas.</p>
            <ul>
              <li>1 equipo máximo</li>
              <li>100 MB de almacenamiento de datos</li>
              <li>2 widgets máximo</li>
            </ul>
            <p>Gratis</p>
            <button onClick={ () => {
              window.location = '/register/free'
            }}>Elegir</button>
          </div>
          <div className="card">
            <h3>Plan Pro</h3>
            <p>Diseñado para usuarios individuales que necesitan más capacidades que el plan gratuito, perfecto para proyectos personales avanzados.</p>
            <ul>
              <li>3 equipos máximo</li>
              <li>500 MB de almacenamiento de datos</li>
              <li>5 widgets máximo</li>
            </ul>
            <p>$15 USD/mes</p>
            <button onClick={ () => {
              window.location = '/register/pro'
            }}>Elegir</button>
          </div>
          <div className="card">
            <h3>Plan Full</h3>
            <p>Adaptado para empresas y proyectos más grandes, ofreciendo recursos ilimitados para una gestión extensa de IoT.</p>
            <ul>
              <li>Equipos ilimitados</li>
              <li>10 GB de almacenamiento de datos</li>
              <li>Widgets ilimitados</li>
            </ul>
            <p>$30 USD/mes</p>
            <button onClick={ () => {
              window.location = '/register/full'
            }}>Elegir</button>
          </div>
        </div>
      </section>

      {/* FAQ Section */}
      <section id="faq" className="faq">
        <Typography sx={{fontFamily: '"Kanit", sans-serif' }} variant="h4" gutterBottom>
          Preguntas Frecuentes
        </Typography>

        <Accordion sx={{ backgroundColor: '#fff', fontFamily: '"Kanit", sans-serif' }}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography sx={{fontFamily: '"Kanit", sans-serif' }}>¿Qué es IOTERIS?</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography sx={{fontFamily: '"Kanit", sans-serif' }}>
              IOTERIS es una plataforma que te permite conectar y monitorear dispositivos IoT en tiempo real, facilitando la visualización y gestión de datos.
            </Typography>
          </AccordionDetails>
        </Accordion>

        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography sx={{fontFamily: '"Kanit", sans-serif' }}>¿Cómo añado un nuevo dispositivo a mi cuenta?</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography sx={{fontFamily: '"Kanit", sans-serif' }}>
              Para añadir un nuevo dispositivo, accede a la pestaña de "Dispositivos", y selecciona la opción "Nuevo dispositivo". Ingresa los datos requeridos y sigue las instrucciones en pantalla.
            </Typography>
          </AccordionDetails>
        </Accordion>

        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography sx={{fontFamily: '"Kanit", sans-serif' }}>¿Qué tipos de widgets están disponibles en IOTERIS?</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography sx={{fontFamily: '"Kanit", sans-serif' }}>
              IOTERIS ofrece de widgets como gráficos de tiempo e indicadores de valor instantáneo. Próximamente agregaremos más.
            </Typography>
          </AccordionDetails>
        </Accordion>

        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography sx={{fontFamily: '"Kanit", sans-serif' }}>¿Cómo configuro alertas y notificaciones?</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography sx={{fontFamily: '"Kanit", sans-serif' }}>
              Las alertas y notificaciones se pueden configurar en la sección de "Alertas" dentro de cada widget. Puedes establecer valores mínimos y máximos para recibir alertas automáticas.
            </Typography>
          </AccordionDetails>
        </Accordion>
      </section>

      {/* Footer */}
      <footer className="footer">
        <h1 className="logo-footer"><span>IOT</span>ERIS</h1>
        <div className="contact">contacto@ioteris.com.ar</div>
      </footer>
    </div>
  );
};
