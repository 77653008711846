import React, { useEffect, useState } from 'react';
import './PopUpAddWidget.scss';
import { Bounce, toast, ToastContainer } from 'react-toastify';
import { createWidget, fetchUserData, fetchUserDevices, fetchUserWidgets } from '../../data/api';

export const PopUpAddWidget = ({ onClose }) => {
    const [title, setTitle] = useState('');
    const [graph, setGraph] = useState('');
    const [isLine, setIsLine] = useState(false);
    const [isNumber, setIsNumber] = useState(false);
    const [minValue, setMinValue] = useState('');
    const [maxValue, setMaxValue] = useState('');
    const [enableMin, setEnableMin] = useState(false);
    const [enableMax, setEnableMax] = useState(false);

    const [variableOptions, setVariableOptions] = useState([]);
    const [selectedVariable, setSelectedVariable] = useState('');
    const [selectedDevice, setSelectedDevice] = useState('');
    const [deviceOptions, setDeviceOptions] = useState([]);

    useEffect(() => {
        setIsLine(graph === 'line');
        setIsNumber(graph === 'number');
    }, [graph]);

    const fetchDeviceOptions = async () => {
        try {
            const data = await fetchUserDevices();
            setDeviceOptions(data.devices);
        } catch (error) {
            console.error('Error al obtener dispositivos:', error);
        }
    };

    useEffect(() => {
        fetchDeviceOptions();
    }, []);

    useEffect(() => {
        if (selectedDevice) {
            const device = deviceOptions.find(device => device._id === selectedDevice);
            setVariableOptions(device ? device.variables : []);
        } else {
            setVariableOptions([]);
        }
    }, [selectedDevice, deviceOptions]);

    const handleSave = async () => {

        // Validación de datos requeridos
        if (!title) {
            toast.error('Por favor, ingresa un título para el widget.', {
                position: "bottom-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
                theme: "dark",
                transition: Bounce,
            });
            return;
        }
        if (!graph) {
            toast.error('Por favor, selecciona el tipo de gráfico.', {
                position: "bottom-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
                theme: "dark",
                transition: Bounce,
            });
            return;
        }
        if (!selectedDevice) {
            toast.error('Por favor, selecciona un dispositivo.', {
                position: "bottom-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
                theme: "dark",
                transition: Bounce,
            });
            return;
        }
        if (!selectedVariable) {
            toast.error('Por favor, selecciona una variable.', {
                position: "bottom-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
                theme: "dark",
                transition: Bounce,
            });
            return;
        }
        if (enableMin && !minValue) {
            toast.error('Por favor, ingresa un valor mínimo para la alerta.', {
                position: "bottom-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
                theme: "dark",
                transition: Bounce,
            });
            return;
        }
        if (enableMax && !maxValue) {
            toast.error('Por favor, ingresa un valor máximo para la alerta.', {
                position: "bottom-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
                theme: "dark",
                transition: Bounce,
            });
            return;
        }
        const user = await fetchUserData();
        const widgets = await fetchUserWidgets();
        if(user.user.plan == 'free'){
            const cant = widgets.widgets.length
            if (cant >= 2) {
                // No puede crear el widget
                toast.error('No puedes crear más widgets con el plan actual', {
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: false,
                    draggable: true,
                    progress: undefined,
                    theme: "dark",
                    transition: Bounce,
                    });
                return
            }
        }

        if(user.user.plan == 'pro'){
            const cant = widgets.widgets.length
            if (cant >= 5) {
                // No puede crear el widget
                toast.error('No puedes crear más widgets con el plan actual', {
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: false,
                    draggable: true,
                    progress: undefined,
                    theme: "dark",
                    transition: Bounce,
                    });
                return
            }
        }

        const widgetConfig = {
            title,
            graphType: graph,
            minValue: enableMin ? minValue : null,
            maxValue: enableMax ? maxValue : null,
            selectedVariable,
            selectedDevice,
        };
        console.log('Widget Config:', widgetConfig);
        const resp = await createWidget({
            userId: localStorage.getItem('userId'),
            title,
            deviceId: selectedDevice,
            deviceDataId: selectedVariable,
            type: graph,
            minValue: enableMin ? minValue : null,
            maxValue: enableMax ? maxValue : null,
        });
        console.log(resp);
        if(resp.status === 'success'){
            toast.success('Widget creado', {
                position: "bottom-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
                theme: "dark",
                transition: Bounce,
            });
                
            setTimeout(() => {
                window.location.reload();
            }, 500);
        } else {
            toast.error(resp.error, {
                position: "bottom-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
                theme: "dark",
                transition: Bounce,
            });
        }
    };

    return (
        <div className='background-popup'>
            <div className='popup-container'>
                <h2>Añadir widget</h2>
                <hr />

                {/* Campo para el título del widget */}
                <div className='widget-title'>
                    <label>Título del widget:</label>
                    <input
                        type="text"
                        value={title}
                        onChange={(e) => setTitle(e.target.value)}
                        placeholder="Ingrese el título del widget"
                    />
                </div>

                <div className='graph-type-list'>
                    <div onClick={() => setGraph('line')} className={`graph-type ${isLine ? 'active' : ''}`}>
                        <img src={require('../../assets/graph-line.png')} alt="Gráfico lineal" />
                        <p>Gráfico lineal</p>
                    </div>
                    <div onClick={() => setGraph('number')} className={`graph-type ${isNumber ? 'active' : ''}`}>
                        <img src={require('../../assets/graph-number.png')} alt="Valor actualizado" />
                        <p>Valor actualizado</p>
                    </div>
                </div>

                <div className='item-variable-list'>
                    <label>Seleccionar Dispositivo:</label>
                    <select value={selectedDevice} onChange={(e) => setSelectedDevice(e.target.value)}>
                        <option value="">Seleccione un dispositivo</option>
                        {deviceOptions.map((device) => (
                            <option key={device._id} value={device._id}>{device.name}</option>
                        ))}
                    </select>

                    <label>Seleccionar Variable:</label>
                    <select value={selectedVariable} onChange={(e) => setSelectedVariable(e.target.value)} disabled={!selectedDevice}>
                        <option value="">Seleccione una variable</option>
                        {variableOptions.map((variable) => (
                            <option key={variable.id} value={variable.id}>{variable.name}</option>
                        ))}
                    </select>
                </div>

                <div className='alert-settings'>
                    <h3>Configuración de alertas</h3>
                    <div className='alert-input'>
                        <label>Habilitar valor mínimo</label>
                        <label className="switch">
                            <input
                                type="checkbox"
                                checked={enableMin}
                                onChange={() => setEnableMin(!enableMin)}
                            />
                            <span className="slider round"></span>
                        </label>
                    </div>
                    {enableMin && (
                        <div className='alert-input'>
                            <label>Valor mínimo:</label>
                            <input
                                type="number"
                                value={minValue}
                                onChange={(e) => setMinValue(e.target.value)}
                                placeholder="Ingrese valor mínimo"
                            />
                        </div>
                    )}

                    <div className='alert-input'>
                        <label>Habilitar valor máximo</label>
                        <label className="switch">
                            <input
                                type="checkbox"
                                checked={enableMax}
                                onChange={() => setEnableMax(!enableMax)}
                            />
                            <span className="slider round"></span>
                        </label>
                    </div>
                    {enableMax && (
                        <div className='alert-input'>
                            <label>Valor máximo:</label>
                            <input
                                type="number"
                                value={maxValue}
                                onChange={(e) => setMaxValue(e.target.value)}
                                placeholder="Ingrese valor máximo"
                            />
                        </div>
                    )}
                </div>

                <div className='button-group'>
                    <button className='btn-popup' id='btn-add' onClick={handleSave}>Guardar</button>
                    <button className='btn-popup' id='btn-delete' onClick={onClose}>Cancelar</button>
                </div>
            </div>
            <ToastContainer
                position="bottom-right"
                autoClose={3000}
                hideProgressBar
                theme="colored"
                transition={Bounce}
            />
        </div>
    );
};
