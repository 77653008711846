import React from 'react'
import './GraphNumber.scss';
import moment from 'moment';

export const GraphNumber = ({data}) => {

    console.log(data);
    
    return (
        <div className='graphnumber'>
            <div className='graphnumber-container'>
                <div className='data-container'>
                    <p>{data.value}</p>
                    <p>{data.unit}</p>
                </div>
                <p className='data-timestamp'>{moment(data.timestamp).format('HH:mm:ss DD/MM/YYYY')}</p>
            </div>
        </div>
    )
}
