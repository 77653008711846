import React, { useEffect, useState } from 'react';
import './SuccessPaymentPage.scss';
import { Bounce, toast, ToastContainer } from 'react-toastify';
import { registerSuccessPayment } from '../../data/api';

export const SuccessPaymentPage = () => {
  const [counter, setCounter] = useState(5);

  useEffect(() => {
    const handlePaymentSuccess = async () => {
      try {
        // Obtener el objeto guardado en localStorage
        const data = JSON.parse(localStorage.getItem('data'));
        console.log(data);
        const email = localStorage.getItem('email');
        
        if ((data && data.email) || email) {
          

          // Llamar a la función registerSuccessPayment con el email
          const resp = await registerSuccessPayment(email);

          // Verificar si la respuesta es exitosa
          if (resp.status === 'success') {
            // Redirige a la página principal después de 5 segundos
            setTimeout(() => {
              window.location.href = '/dashboards';
            }, 5000);
          } else {
            console.error('Error registrando el pago:', resp.error);
            toast.error(resp.error, {
                position: "bottom-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
                theme: "dark",
                transition: Bounce,
            });
          }
        } else {
          console.error('No se encontró el email en el data de localStorage.');
        }
      } catch (err) {
        console.error('Error obteniendo datos del localStorage o enviando el email:', err);
      }
    };

    handlePaymentSuccess();

    // Temporizador para la cuenta regresiva
    const timer = setInterval(() => {
      setCounter((prevCounter) => prevCounter - 1);
    }, 1000);

    return () => {
      clearInterval(timer);
    };
  }, []);

  return (
    <div className="success-container">
      <div className="success-box">
        <h2>Registro Exitoso</h2>
        <p>Será redirigido a la página principal en {counter} segundos.</p>
      </div>
      <ToastContainer
        position="bottom-right"
        autoClose={5000}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover={false}
        theme="dark"
        transition={Bounce}
      />
    </div>
  );
};
